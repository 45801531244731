.Wrapper {
  /* border: 1px solid red; */
  padding: 3% 10%;
  font-family: "Saira Semi Condensed", sans-serif;
}

img.Loading {
  background: transparent url(https://gph.is/2ytRD4W) no-repeat scroll center center;
}

.Resume {
  /* border: 1px solid red; */
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 1fr 2fr;
  grid-template-areas:
    "resumeTop resumeTop"
    "resumeL resumeR";
}

.ResumeTop {
  /* border: 1px solid red; */
  grid-area: resumeTop;
  display: flex;
  margin: 0 0 2% 0;
}

.ResumeTop>h2 {
  /* border: 1px solid red; */
  margin: 0;
  font-weight: 600;
  letter-spacing: 0;
  color: #5e88a4;
}

.ResumeTop>a>div {
  /* border: 1px solid red; */
  margin: 9px 0 0 20px;
}

.ResumeTop>a>div>svg {
  /* border: 1px solid red; */
  height: 22px;
  width: 18px;
}

.ResumeTop>a>div>svg>path {
  fill: darkgray;
  transition: fill 0.4s;
}

.ResumeTop>a>div>svg:hover path {
  fill: #5e88a4;
}

.ResumeLeft {
  /* border: 1px solid red; */
  grid-area: resumeL;
  display: grid;
  grid-template-rows: 0.25fr 0.25fr 0.5fr;
  grid-template-areas:
    "resumeLsumm"
    "resumeLskills"
    "resumeLrecog";
}

.ResumeLeft_Summary {
  /* border: 1px solid red; */
  grid-area: resumeLsumm;
}

.ResumeLeft_Summary>p>a {
  text-decoration: none;
  color: black;
  transition: 0.4s;
}

.ResumeLeft_Summary>p>a:hover {
  color: #5e88a4;
}

.ResumeLeft_Skills {
  /* border: 1px solid red; */
  grid-area: resumeLskills;
  display: grid;
  margin-bottom: 5%;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 1fr 1fr 0.5fr;
  grid-template-areas:
    "resumeLskillsTitle resumeLskillsTitle ."
    "resumeLskillsL resumeLskillsR .";
}

.ResumeLeft_Skills>h3 {
  /* border: 1px solid red; */
  grid-area: resumeLskillsTitle;
}

.ResumeLeft_Skills__L {
  /* border: 1px solid red; */
  grid-area: resumeLskillsL;
}

.ResumeLeft_Skills__R {
  /* border: 1px solid red; */
  grid-area: resumeLskillsR;
}

.ResumeLeft_Recognition {
  /* border: 1px solid red; */
  grid-area: resumeLrecog;
}

.ResumeLeft_Recognition a {
  text-decoration: none;
  color: black;
}

.ResumeRight {
  /* border: 1px solid red; */
  grid-area: resumeR;
  display: grid;
  padding-left: 8%;
  grid-template-rows: 2fr 0.55fr;
  grid-template-areas:
    "resumeRexp"
    "resumeRedu";
}

.ResumeRight_Experience {
  /* border: 1px solid red; */
  grid-area: resumeRexp;
}

.ResumeRight__Company_Dates {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
}

.ResumeRight_Education>h4,
.ResumeRight_Experience>h4 {
  /* border: 1px solid red; */
  font-size: 20px;
  font-weight: 700;
  color: #5e88a4;
  align-items: flex-end;
  margin: 1% 0;
}

.ResumeRight__Company_Dates>h5 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  display: flex;
  transition: 0.4s;
}

.ResumeRight__Company_Dates>h5>a {
  height: 27px;
  text-decoration: none;
  color: black;
  transition: 0.4s;
  /* -webkit-transition: -webkit-transform 0.8s ease-in-out; */
  /* transition: transform 0.8s ease-in-out; */
}

.ResumeRight__Company_Dates>h5>a:hover {
  /* -webkit-transform: rotate(360deg); */
  /* transform: rotate(360deg) */
  color: #5e88a4;

}

.ResumeRight__Company_Dates>h6 {
  /* border: 1px solid red; */
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}

.ResumeRight_Education {
  /* border: 1px solid red; */
  grid-area: resumeRedu;
}

.ResumeLeft_Skills>ul,
.ResumeLeft_Recognition>ul,
.ResumeRight_Experience>ul,
.ResumeRight_Education>ul {
  /* border: 1px solid blue; */
  margin: 2% 0 4% 0%;
  font-size: 16px;
}

.ResumeRight_Experience>ul {
  /* border: 1px solid blue; */
  margin: 2% 0 4% 3%;
}

.ResumeLeft_Recognition>ul>li,
.ResumeRight_Experience>ul>li,
.ResumeRight_Experience>ul>li>a {
  margin: 0.5% 0% 1% 0%;
  list-style-type: disc;
  text-decoration: none;
  color: black;
  transition: 0.4s;
}

.ResumeRight_Experience>ul>li>a:hover {
  color: #5e88a4;
}

.ResumeLeft_Skills>ul>li,
.ResumeLeft_Recognition>ul>li {
  list-style-type: none;
}

.ResumeLeft_Skills__L>ul,
.ResumeLeft_Skills__R>ul {
  margin: 5% 0 0 0;
}

.ResumeLeft_Skills__L>ul>li>a,
.ResumeLeft_Skills__R>ul>li>a {
  /* border: 1px solid red; */
  align-items: center;
  margin: 0% 0% 3% 0%;
  display: flex;
  height: 50px;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
}

.ResumeLeft_Skills__L>ul>li>a>div,
.ResumeLeft_Skills__R>ul>li>a>div {
  margin: 5% 7% 0% 0%;
}

.ResumeLeft_Skills__L>ul>li>a>div>svg>circle,
.ResumeLeft_Skills__R>ul>li>a>div>svg>circle {
  fill: #5e88a4;
}

.ResumeLeft_Skills__L>ul>li>a>div>svg,
.ResumeLeft_Skills__R>ul>li>a>div>svg {
  -webkit-transition: -webkit-transform 0.7s ease-in-out;
  transition: transform 0.7s ease-in-out;
}

.ResumeLeft_Skills__L>ul>li>a,
.ResumeLeft_Skills__R>ul>li>a {
  transition: 0.4s;
}

.ResumeLeft_Skills__L>ul>li>a:hover div>svg,
.ResumeLeft_Skills__R>ul>li>a:hover div>svg {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.ResumeLeft_Skills__L>ul>li>a:hover,
.ResumeLeft_Skills__R>ul>li>a:hover {
  color: #5e88a4;
}

.ResumeLeft_Summary>h3,
.ResumeLeft_Skills>h3,
.ResumeLeft_Recognition>h3,
.ResumeRight_Experience>h3,
.ResumeRight_Education>h3 {
  /* border: 1px solid blue; */
  font-size: 20px;
  font-weight: 600;
  margin: 1% 0%;
  letter-spacing: 0;
}

.ResumeRight_Education .ResumeRight__Company_Dates {
  margin: 0 0 4% 0;
}

.ResumeRight_Education>h3 {
  margin: 1% 0 2% 0;
}

.ResumeLeft_Summary>p {
  /* border: 1px solid red; */
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
  margin: 3% 0% 4% 0;
  letter-spacing: 0;
}

@media (max-width: 1000px) {
  .Wrapper {
    width: 100vw;
    padding: 0;
    margin: 0;
  }

  .Resume {
    /* border: 1px solid red; */
    padding: 3% 0 0 0;
    margin: auto;
    width: 90vw;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, auto);
    grid-template-areas:
      "resumeTop"
      "resumeL"
      "resumeR";
  }

  .ResumeTop {
    margin: auto;
    padding: 3% 0 4% 0;
  }

  .ResumeLeft_Summary p {
    padding: 0 2%;
    /* text-align: left; */
  }

  .Resume h3 {
    /* border: 1px solid red; */
    /* background-color: #5e88a4; */
    background-color: lightslategrey;
    font-size: 20px;
    color: white;
    text-align: center;
    border-radius: 5px;
  }

  .Resume h4 {
    font-size: 18px;
  }

  .Resume h5 {
    font-size: 16px;
  }

  .ResumeLeft {
    /* border: 1px solid red; */
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      "resumeLsumm"
      "resumeLskills";
  }

  .ResumeLeft_Skills {
    /* border: 1px solid red; */
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      "resumeLskillsTitle resumeLskillsTitle"
      "resumeLskillsL resumeLskillsR";
  }

  .ResumeLeft_Recognition {
    display: none;
  }

  .ResumeLeft_Recognition iframe {
    width: 90vw;
    height: 40vh;
  }

  .ResumeRight {
    padding: 0;
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      "resumeRexp"
      "resumeRedu";
  }

  .ResumeRight_Experience h3 {
    /* border: 1px solid red; */
    margin: 2% 0 4% 0;
  }

  .ResumeRight__Company_Dates>h6 {
    font-size: 12px;
    padding: .6% 0;
    text-align: right;
  }

  .ResumeRight_Education {
    /* border: 1px solid red; */
    margin: 0 0 3% 0;
  }

  .ResumeRight_Experience ul {
    /* border: 1px solid red; */
    margin: 0;
    padding: 0 0 1% 5.5%;
  }

  .ResumeRight_Experience ul li {
    /* border: 1px solid red; */
    margin: 2% 0 3% 0;
  }
}