.Music {
  margin: auto;
}

.Hero {
  /* border: 1px solid red; */
  display: block;
  position: relative;
  width: 100vw;
  /* background-image: url('../../../assets//Images/RyanDrums.jpg'); */
  background-size: cover;
  min-height: 95vh;
}

.HeroDetail {
  /* border: 2px solid blue; */
  color: white;
  position: absolute;
  width: 90%;
  bottom: 50px;
  left: 50px;
  padding: 2%;
}

.HeroDetail p {
  /* border: 1px solid green; */
  color: white;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 25px;
  font-weight: 300;
}

.MusicProjects {
  /* border: 1px solid red; */
  padding: 3% 10%;
  display: grid;
  grid-template-columns: repeat (3, 1fr);
  grid-template-rows: .5fr 3fr;
  grid-template-areas:
    "mscprjtitle mscprjtitle mscprjtitle"
    "prjsctn1 prjsctn2 prjsctn3";
}

.MusicProjects h1 {
  /* border: 1px solid red; */
  grid-area: mscprjtitle;
  margin: 0;
}

@media (max-width: 1000px) {
  .Music {
    /* border: 3px solid red; */
    width: 100vw;
  }

  .Hero {
    /* border: 1px solid red; */
    width: 100vw;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    background-position: center;
    padding: 0;
  }

  .HeroDetail {
    /* border: 1px solid red; */
    height: 43vh;
    width: 95vw;
    bottom: 30px;
    left: 10px;
    padding: 0;
  }

  .HeroDetail h1 {
    /* border: 1px solid red; */
    height: 3vh;
    font-size: 24px;
  }

  .HeroDetail p {
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-transform: none;
    -webkit-text-stroke: .3px black;
    /* text-align: justify; */
  }


  .MusicProjects {
    /* border: 3px solid red; */
    display: block;
    margin: 8% 0 3% 0;
    padding: 0;
  }

  .MusicProjects h1 {
    display: none;
  }
}