.ProjectSection {
  /* border: 1px solid red; */
  display: grid;
  padding: 0 8%;
  grid-template-rows: .25fr .15fr 1fr;
  grid-template-areas:
    "prjsctnimg"
    "prjsctntitle"
    "prjsctndtl";
}

.ProjectSectionImg {
  grid-area: prjsctnimg;
  position: relative;
  width: 400px;
  margin: auto;
}

.ProjectSectionImg img {
  border-radius: 50%;
  width: 100%;
}

.ProjectSectionTitle {
  /* border: 1px solid green; */
  grid-area: prjsctntitle;
}

.ProjectSectionDetail {
  /* border: 1px solid green; */
  grid-area: prjsctndtl;
}

@media (max-width: 1000px) {
  .ProjectSection {
    /* border: 1px solid red; */
    display: block;
    padding: 0;
    margin: auto;
    width: 90vw;
  }

  .ProjectSectionImg {
    /* border: 1px solid green; */
    padding: 0;
    width: 100%;
  }

  .ProjectSectionImg img {
    border-radius: 50%;
  }

  .ProjectSectionTitle {
    height: 30px;
  }

  .ProjectSectionDetail {
    /* border: 1px solid green; */
    padding: 0;
  }
}