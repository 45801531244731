.Wrapper {
  /* border: 1px solid red; */
  background: #5E88A4;
  display: grid;
  height: 310px;
  /* width: 100vw; */
  text-align: center;
  /* position: relative; */
  /* z-index: 10; */
}

.Footer {
  /* border: 1px solid red; */
  width: 85%;
  margin: auto;
  grid-template-columns: repeat(5, 240px);
  grid-template-rows: repeat(4, 50px);
  grid-template-areas:
    ". . logo . ."
    "line line line line line"
    ". . links . ."
    ". . par . ."
  ;
}

.Logo {
  /* border: 1px solid red; */
  justify-content: center;
}

/* Set Logo size */
.Logo>div>svg {
  /* border: 1px solid red; */
  grid-area: logo;
  width: 104px;
  height: 34px;
}

/* Set Logo fill color */
.Logo>div>svg>path {
  fill: white;
}

.Line {
  grid-area: line;
  width: 100%;
  height: 1px;
  margin-top: 30px;
  background: white;
  box-shadow: 0px 2px 4px rgb(36, 36, 36);
}

.Links {
  /* border: 1px solid red; */
  grid-area: links;
  margin-top: 2%;
}

.Links>ul {
  /* border: 1px solid red; */
  justify-content: center;
  display: flex;
}

.Links>ul>li {
  margin: 0 .7%;
}

.Links svg circle {
  transition: fill .9s;
}

.Links svg path {
  transition: fill .2s;
}

.Links li:hover svg circle {
  fill: white;
}

.Links li:hover svg path {
  fill: #4B6B80;
}

.Footer p {
  /* border: 1px solid red; */
  grid-area: par;
  font-size: small;
  text-transform: uppercase;
  color: white;
  margin-top: 2%;
}

@media (max-width: 1000px) {

  .Wrapper {
    display: none;
  }
}