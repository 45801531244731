.Wrapper {
	/* border: 2px solid red; */
	width: 100vw;
	padding: 3% 0 2.5% 0;
}

.Wrapper>div {
	outline: none;
}

@media (max-width: 1000px) {
	.Wrapper {
		height: 100vh;
		width: 100vw;
	}
}