.Technology {
	/* border: 1px solid red; */
	width: 75%;
}

.PortfolioImage {
	/* border: 1px solid red; */
	z-index: 1;
	position: relative;
}

.PortfolioImage img {
	border-radius: 50%;
	width: 100%;
	height: 100%;
	background-color: lightblue;
}

.PortfolioImage .PortfolioInfo {
	/* border: 1px solid red; */
	background: linear-gradient(0deg,
			rgba(2, 0, 36, 0) 40%,
			rgba(9, 9, 121, 0) 40%,
			rgb(70, 90, 133) 85%);
	width: 100%;
	height: 100%;
	border-radius: 50%;
	z-index: 2;
	position: absolute;
}

.PortfolioInfo ul>li>div>svg {
	/* border: 1px solid red; */
	height: 45px;
	width: 45px;
}

.PortfolioInfo svg>circle {
	fill: none;
}

.PortfolioInfo svg>path {
	fill: white;
}

.PortfolioInfo>h3 {
	/* border: 1px solid red; */
	color: white;
	text-align: center;
	z-index: 3;
}

.PortfolioInfo>ul {
	/* border: 1px solid red; */
	justify-content: center;
	display: flex;
	position: relative;
	z-index: 3;
	padding: 0;
}

.PortfolioInfo>ul>li {
	/* border: 1px solid red; */
	padding: 0.2%;
}

.Details {
	/* border: 1px solid red; */
	margin: auto;
	width: 85%;
	display: grid;
	padding: 3% 0 4% 0;
	grid-template-rows: repeat(2, auto);
	grid-template-areas:
		"head head"
		"text text";
}

.Details__header {
	/* border: 1px solid red; */
	grid-area: head;
	display: flex;
}

.PortfolioInfo>ul,
.Details__header>ul {
	/* border: 1px solid red; */
	align-items: center;
	grid-area: links;
	display: flex;
	list-style-type: none;
}

li {
	/* border: 1px solid red; */
	grid-area: link;
	padding: 0 5px;
}

.Details__header svg>circle {
	fill: #4b6b80;
}

.Details__header svg {
	margin: 0 0 0 25%;
	-webkit-transition: -webkit-transform 0.8s ease-in-out;
	transition: transform 0.8s ease-in-out;
}

.Details__header svg:hover {
	-webkit-transform: rotate(360deg);
	transform: rotate(360deg);
}

.Details p {
	margin: 0;
	grid-area: text;
	width: 100%;
	letter-spacing: 0;
	text-align: justify;
	line-height: 1.6;
	margin: 0 0 2% 0;
}

@media (max-width: 1000px) {
	.Technology {
		/* border: 1px solid red; */
		height: 100%;
		width: 95vw;
		margin: 0;
		margin: auto;
	}

	.PortfolioInfo>h3 {
		/* border: 1px solid red; */
		font-size: 20px;
		padding: 0;
		margin: 4% 0 0 0;
	}

	.PortfolioInfo ul {
		/* border: 1px solid red; */
		margin: 3%;
	}

	.PortfolioInfo>ul>li {
		/* border: 1px solid red; */
		padding: 0 0.1% 0 0.1%;
	}

	.PortfolioInfo ul>li>div>svg {
		/* border: 1px solid red; */
		height: 35px;
		width: 35px;
	}

	.Details {
		/* border: 1px solid red; */
		width: 74vw;
		padding: 4% 6% 6% 6%;
		margin-top: 6%;
		background-color: white;
		border-radius: 20px;
	}

	.Details h2 {
		font-size: 24px;
		margin: 0;
	}

	.Details p {
		margin: 0;
		line-height: 24px;
		text-align: left;
	}

	.Details__header {
		/* border: 1px solid red; */
		display: inline-flex;
		margin: 0 0 5% 0;
	}

	.Details__header ul {
		/* border: 1px solid red; */
		margin: 1% 0 3% 5%;
	}

	.Details__header ul li {
		/* border: 1px solid red; */
		margin: 0;
		padding: 0;
		height: 30px;
		width: 30px;
	}

	.Details__header svg {
		margin: 0;
		padding: 0;
		height: 100%;
		width: 100%;
		-webkit-transition: none;
		transition: none;
	}
}