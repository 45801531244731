.Navigation {
  background: white;
  width: 100%;
  margin: auto;
  z-index: 20;
}

@media (max-width: 999px) {
  .Navigation {
    display: none;
  }
}