.Layout {
  display: grid;
  background: #F6F6F6;
  grid-template-rows: 60px [navigation] auto [content] 310px [footer];
}

@media (max-width: 999px) {
  .Layout {
    /* display: grid; */
    grid-template-rows: auto [content];
  }
}