body {
	margin: 0;
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #000000;
}

p {
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 30px;
	letter-spacing: 0.03em;
	color: rgba(0, 0, 0, 0.8);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	text-transform: uppercase;
}

h1 {
	font-weight: bold;
	font-size: 48px;
	line-height: 72px;
	display: flex;
	align-items: center;
	letter-spacing: 0.125em;
}

h2 {
	font-style: normal;
	font-weight: 500;
	font-size: 36px;
	line-height: 36px;
	letter-spacing: 0.125em;
}

h3 {
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	letter-spacing: 0.125em;
}

h4 {}

h5 {}

h6 {}

ul {
	list-style-type: none;
	padding: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.slick-slide {
	opacity: 0.3;
	transition: opacity .3s ease-in-out;
	-moz-transition: opacity .3s ease-in-out;
	-webkit-transition: opacity .3s ease-in-out;
	outline: none;
}

.slick-center {
	/* border: 1px solid red; */
	opacity: 1;
	transition: opacity .4s ease-in-out;
	-moz-transition: opacity .4s ease-in-out;
	-webkit-transition: opacity .4s ease-in-out;
}

.slick-slider>button {
	/* border: 1px solid red; */
	position: absolute;
	top: 100%;
}

.slick-next {
	right: 48.5%;
	color: black;
}

.slick-prev {
	left: 48%;
	color: black;
}

.slick-next:before,
.slick-prev:before {
	color: #9d9d9d;
	opacity: 1;
	font-size: 30px;
}

.slick-next:before:hover button,
.slick-prev:before:hover button {
	color: #4b6b80;
}

@media (max-width: 1000px) {
	.slick-current {
		opacity: 1;
	}
}