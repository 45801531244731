.Wrapper {
  overflow-y: hidden;
  /* Hide vertical scrollbar */
  overflow-x: hidden;
  /* Hide horizontal scrollbar */
  background: #F6F6F6;
  height: 100vh;
}

.Landing {
  text-align: center;
}

.Landing h4 {
  display: none;
}

.Logo>div {
  grid-area: logo;
  width: 294px;
  height: 94px;
  margin: auto;
  padding-top: 7%;
  /* opacity: 0.7; */
}

.hihat,
.crashL,
.crashR,
.tom,
.snare,
.ride,
.floorTom {
  overflow: hidden;
}

.hihat {
  height: 45%;
  width: 18%;
  grid-area: hihat;
  z-index: 3;
  position: absolute;
  left: 10%;
  /* right: 73.39%; */
  /* top: 57%; */
  bottom: 0%;
  transition: .4s ease-in-out;
}

.crashL {
  height: 76%;
  width: 22%;
  grid-area: crashL;
  z-index: 1;
  position: absolute;
  left: 17%;
  /* right: 62.5%; */
  /* top: 29%; */
  bottom: 0%;
  transition: .4s ease-in-out;
}

.tom {
  height: 45%;
  width: 13%;
  grid-area: tom;
  z-index: 2;
  position: absolute;
  left: 34%;
  /* right: 51.28%; */
  /* top: 57%; */
  bottom: 0%;
  transition: .4s ease-in-out;
}

.snare {
  height: 30%;
  width: 19%;
  grid-area: snare;
  z-index: 4;
  position: absolute;
  left: 31%;
  /* right: 48.39%; */
  /* top: 70%; */
  bottom: 0%;
  transition: .4s ease-in-out;
}

.ride {
  height: 55%;
  width: 30%;
  grid-area: ride;
  z-index: 2;
  position: absolute;
  left: 48%;
  /* right: 25%; */
  /* top: 43%; */
  bottom: 0%;
  transition: .4s ease-in-out;
}


.floorTom {
  height: 30%;
  width: 25%;
  grid-area: floorTom;
  z-index: 5;
  position: absolute;
  /* right: 18%; */
  left: 60%;
  /* top: 70%; */
  bottom: 0%;
  transition: .4s ease-in-out;
}

.crashR {
  height: 76%;
  width: 25%;
  grid-area: crashR;
  z-index: 1;
  position: absolute;
  /* left: 65%; */
  right: 10%;
  /* top: 29%; */
  bottom: 0%;
  transition: .4s ease-in-out;
}

.crashL:hover,
.crashR:hover,
.hihat:hover,
.ride:hover,
.tom:hover,
.snare:hover,
.floorTom:hover {
  -webkit-transform: translate(0, 1.5em);
  transform: translate(0, 1.5em);
}

@media (max-width: 1000px) {

  .hihat svg,
  .crashL svg,
  .crashR svg,
  .tom svg,
  .snare svg,
  .ride svg,
  .floorTom svg {
    display: none;
    /* position: absolute; */
  }

  .hihat,
  .crashL,
  .crashR,
  .tom,
  .snare,
  .ride,
  .floorTom {
    display: flex;
  }

  .crashL:hover,
  .crashR:hover,
  .hihat:hover,
  .ride:hover,
  .tom:hover,
  .snare:hover,
  .floorTom:hover {
    -webkit-transform: none;
    transform: none;
  }

  .Landing a h4 {
    /* border: 1px solid red; */
    display: block;
    color: white;
    font-weight: 900;
    font-size: 18px;
    letter-spacing: 3px;
    margin: auto;
    padding-bottom: 4%;
  }

  .tom {
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
  }

  .ride {
    box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.25);
  }

  .crashL,
  .crashR {
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
  }

  .hihat,
  .snare,
  .floorTom {
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  }

  .hihat {
    background-color: #DAE6F1;
    border-radius: 50%;
    height: 115px;
    width: 115px;
    z-index: 2;
    top: 20%;
    left: 15%;
    text-align: center;
  }

  .crashL {
    background-color: #EDDED6;
    border-radius: 50%;
    height: 145px;
    width: 145px;
    z-index: 3;
    top: 15%;
    left: 50%;
  }


  .tom {
    background-color: #DBE0D9;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    z-index: 2;
    top: 35%;
    left: 40%;
  }

  .snare {
    background-color: #C0C3D9;
    border-radius: 50%;
    height: 130px;
    width: 130px;
    z-index: 1;
    top: 35%;
    left: 8%;
  }

  .ride {
    background-color: #F9A9A9;
    border-radius: 50%;
    height: 210px;
    width: 210px;
    z-index: 3;
    top: 48%;
    left: 40%;
  }

  .floorTom {
    background-color: #CADBE2;
    border-radius: 50%;
    height: 160px;
    width: 160px;
    z-index: 1;
    top: 59%;
    left: 8%;
  }

  .crashR {
    background-color: #B8E5FF;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    z-index: 4;
    top: 75%;
    left: 30%;
  }

  .Logo {
    /* border: 1px solid red; */
  }

  .Logo>div {
    /* border: 1px solid red; */
    padding: 0;
    margin: 3%;
    width: 105px;
    height: 34px;
  }

  .Logo>div>svg {
    /* border: 1px solid red; */
    width: 100%;
    height: 100%;
  }
}