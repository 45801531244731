.NavigationItems {
  /* border: 1px solid red; */
  height: 60px;
  width: 85%;
  display: grid;
  grid-template-columns: 1fr repeat(4, auto) 1fr;
  grid-template-areas:
    "Logo NavItem NavItem NavItem NavItem External";
  align-items: center;
  margin: auto;
}

.Logo {
  /* border: 1px solid red; */
  height: 30px;
  width: 96px;
  grid-area: Logo;
}

.Logo svg {
  /* border: 1px solid red; */
  height: 100%;
  width: 100%;
}

.Logo div svg path {
  transition: fill .3s;
}

.Logo div svg:hover path {
  fill: #838ac0;
}

.External {
  /* border: 1px solid red; */
  grid-area: External;
  display: grid;
  justify-content: right;
  grid-template-columns: repeat(2, 60px);
  grid-template-areas: "LinkedIn Github";

}

.External a div {
  /* border: 1px solid red; */
  width: 100%;
  text-align: right;
}

.External svg path {
  transition: fill .3s;
}

.External svg:hover path {
  fill: #4B6B80;
}

.LinkedIn {
  grid-area: LinkedIn
}

.Github {
  grid-area: Github
}

.Github svg circle,
.LinkedIn svg circle {
  display: none;
}

.Github svg path,
.LinkedIn svg path {
  fill: #94a5b1;
}

.Email,
.Medium {
  display: none;
}

@media (max-width: 999px) {
  .NavigationItems {
    /* border: 1px solid red; */
    display: block;
    height: 100%;
    margin: 0;
    width: 100%;
    text-align: right;
  }

  .Logo {
    display: none;
  }

  .External {
    /* border: 1px solid red; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 3%;
  }

  .External svg:hover path {
    fill: white;
  }

  .Email,
  .Medium {
    display: block;
  }

  .Github svg circle,
  .LinkedIn svg circle {
    display: block;
  }

  .Github svg path,
  .LinkedIn svg path {
    fill: white;
  }

  .Email svg,
  .Medium svg,
  .Github svg,
  .LinkedIn svg {
    width: 100%;
    height: 100%;
  }

  .Email,
  .Medium,
  .Github,
  .LinkedIn {
    width: 50px;
    height: 50px;
    padding: 15px;
    margin: 5px;
  }

}